import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  padding: 100px 30px 0;
  margin: 0 auto;
  .sorry {
    padding: 40px 0;
    text-align: center;
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
    }
  }
`;
