import React from 'react';
import Head from 'components/head';
import PageNotFound from 'components/notfound';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <Head pageTitle="404" />
    <PageNotFound />
  </Layout>
);

export default NotFound;
