import React, { Fragment } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'gatsby';
import { Container } from './notfound.css';
import animationData from '../../images/animations/lf30_editor_VxfaYG.json';

const PageNotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Container>
        <Fragment>
          <Lottie options={defaultOptions} height={300} width={300} />
          <div className="sorry">
            <p>Sorry this page doesn&apos;t exist!</p>
            <p>
              Check our <Link to="/">homepage</Link> instead!
            </p>
          </div>
        </Fragment>
      </Container>
    </>
  );
};

export default PageNotFound;
